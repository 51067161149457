import hyRequest from '@/service';
import { API } from '@/config/api';
import {
  ReadPreformTrendData,
  ReadPreformTrendParams,
  ReadPreformContrastData,
  ReadPreformContrastParams,
  ReadPreformReportTableData,
  ReadPreformOrderReportData,
  ReadPreformReportTableParams,
  ReadPreformOrderReportParams,
  ReadPreformSearchByTimeParams,
  ReadPreformSearchByTimePageData
} from '@/interface/api/preform-count';

// 统计
export function readPreformOrderReport(data: ReadPreformOrderReportParams) {
  return hyRequest.post<ReadPreformOrderReportData>({
    url: API.PREFORM_PREFORMORDERREPORT,
    data
  });
}

// 比例
export function readPreformContrast(data: ReadPreformContrastParams) {
  return hyRequest.post<ReadPreformContrastData>({
    url: API.PREFORM_PREFORMCONTRAST,
    data
  });
}

// 统计明细
export function readPreformReportTable(data: ReadPreformReportTableParams) {
  return hyRequest.post<ReadPreformReportTableData>({
    url: API.PREFORM_PREFORMREPORTTABLE,
    data
  });
}

// 趋势
export function readPreformTrend(data: ReadPreformTrendParams) {
  return hyRequest.post<ReadPreformTrendData>({
    url: API.PREFORM_PREFORMTREND,
    data
  });
}

// 记录
export function readPreformSearchByTime(data: ReadPreformSearchByTimeParams) {
  return hyRequest.post<ReadPreformSearchByTimePageData>({
    url: API.PREFORM_PREFORMSEARCHBYTIME,
    data
  });
}
