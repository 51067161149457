import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.pad-start.js";
import { CHAR } from '@/config/common';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);
export default function useDatePicker() {
  var visibleOptionNum = ref(10);
  var rangeErrorPrev = ref("\u6700\u65E9\u53EF\u67E5".concat(10, "\u5E74\u524D\u6570\u636E"));
  var rangeErrorNext = ref('未来不可查');
  var titleTabs = reactive({
    startTime: '开始时间',
    endTime: '结束时间'
  });
  var minYear = computed(function () {
    return dayjs().subtract(10, 'year').year();
  });
  function spacer(value) {
    return _.join([CHAR.SPACE, value, CHAR.SPACE], CHAR.NUL);
  }
  function zeroFill(n) {
    return _.padStart(String(n), 2, String(0));
  }
  function isCurrentUnit(endTime, unit) {
    var s = dayjs().startOf(unit);
    var e = dayjs().endOf(unit);
    return endTime.isBetween(s, e, unit, '[]');
  }
  return {
    spacer: spacer,
    minYear: minYear,
    zeroFill: zeroFill,
    titleTabs: titleTabs,
    isCurrentUnit: isCurrentUnit,
    rangeErrorPrev: rangeErrorPrev,
    rangeErrorNext: rangeErrorNext,
    visibleOptionNum: visibleOptionNum
  };
}